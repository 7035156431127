import React from "react"

import Page from "../components/layout/Page"
import SEO from "../components/seo"

import PageHeader from "../components/layout/PageHeader"

const PrivacyPolicyPage = () => (
  <>
    <SEO page="privacy-policy" title="SignMoney Privacy Policy" />
    <Page
        breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Privacy Policy", url:null}
        ]}
    >
		<PageHeader title="SignMonkey Privacy Policy" />		
		<p>
		SignMonkey.com is committed to protecting your privacy while providing quality services that give you an enjoyable and safe online experience. This Statement of Privacy applies to the SignMonkey.com website and governs data collection and usage. By using the SignMonkey.com website, you consent to the data practices described in this statement. The SignMonkey.com website is the website contained at <a href="www.SignMonkey.com">www.SignMonkey.com</a> and all pages and subpages therein.
		</p>
		
		<h2>Collection of your Personal Information</h2>
		<p>
		SignMonkey.com collects personally identifiable information when you register or order, such as your e-mail address, physical address, name, and phone number, and/or credit card information. SignMonkey.com also collects information not personally identifiable, such as zip code, city and state.
		</p>
		<p>
		There is also information about your computer hardware and software that is automatically collected by SignsMonkey.com. This information can include: your IP address, browser type, domain names, access times and referring Web site addresses, templates made, path through the site, and operating system information. This information is used by SignMonkey.com for the operation of its services, to maintain quality of service, and to provide general statistics regarding use of the SignMonkey.com web site.
		</p>
		<p>
		SignMonkey.com encourages the review of the privacy statements of Web sites you link to from SignMonkey.com so that you can understand how those Web sites collect, use and share your information. SignMonkey.com is not responsible for the privacy statements or other content on Web sites outside of the SignMonkey.com web site.
		</p>
		
		<h2>Use of your Personal Information</h2>
		<p>
		SignMonkey.com collects and uses your personal information to operate the SignMonkey.com web site and deliver the services you have requested. SignMonkey.com also uses your personally identifiable information from time to time to inform you of other products or services available from SignMonkey.com and its affiliates. SignMonkey.com may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered. Note that at any time you may opt out of being contacted by SignMonkey.com for any research or product updates, through clear links at the bottom of every email.
		</p>
		<p>
		SignMonkey.com does not sell, rent or lease its customer lists to third parties. SignMonkey.com may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, SignMonkey.com may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to SignMonkey.com, and they are required to maintain the confidentiality of your information.
		</p>
		<p>
		SignMonkey.com does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.
		</p>
		<p>
		SignMonkey.com keeps track of web sites and pages our customers visit within and from the SignMonkey.com web site, in order to determine what SignMonkey.com services are the most popular. This data is used to deliver customized content and advertising within SignMonkey.com to customers whose behavior indicates that they are interested in a particular subject area.
		</p>
		<p>
		SignMonkey.com will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on SignMonkey.com or the site; (b) protect and defend the rights or property of SignsMonkey.com; and, (c) act under exigent circumstances to protect the personal safety of users of SignMonkey.com, or the public.
		</p>
		
		<h2>Use of Cookies</h2>
		<p>
		The SignMonkey.com web site may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
		</p>
		<p>
		One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you register with the SignMonkey.com site or services, a cookie helps SignMonkey.com to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same SignMonkey.com web site, the information you previously provided can be retrieved, so you can easily use the SignMonkey.com features that you customized.
		</p>
		<p>
		You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the SignMonkey.com services or Web sites you visit.
		</p>
		
		<h2>Security of your Personal Information</h2>
		<p>
		SignMonkey.com secures personal information from unauthorized access, use or disclosure. SignMonkey.com secures personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is protected by encryption, such as the Secure Socket Layer (SSL) protocol.
		</p>
		<p>
		SignMonkey.com will not record, store, or save your Credit Card Number without your express consent and notice from SignMonkey.com.
		</p>
		
		<h2>Changes to this Statement</h2>
		<p>
		SignMonkey.com will occasionally update this Statement of Privacy to reflect company and customer feedback. SignMonkey.com encourages you to periodically review this Statement to be informed of how SignMonkey.com is protecting your information.
		</p>
		
		<h2>Contact Information</h2>
		<p>
		SignMonkey.com welcomes your comments regarding this Statement of Privacy. If you believe SignMonkey.com has not adhered to this Statement, please use the inquiry form to contact us. We will use commercially reasonable efforts to promptly determine and remedy the problem.
		</p>
    </Page>
  </>
)

export default PrivacyPolicyPage